.terms-and-conditions {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    max-width: 450px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
  .terms-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
    background-color: #ffffff;
  }
  
  .terms-inner {
    max-width: 100%;
  }
  
  .termAndConditions {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 8px;
  }
  
  .termAndConditionsWelcome {
    font-size: 20px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 16px;
  }
  
  .termAndConditionBasicDetails {
    margin-bottom: 24px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .termAndConditionBasicDetails h1,
  .termAndConditionBasicDetails h2 {
    font-size: 18px;
    font-weight: 600;
    color: #3498db;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  .termAndConditionBasicDetails p {
    font-size: 14px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 12px;
  }
  
  .showAndList {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 12px;
  }
  
  .showAndList li {
    font-size: 14px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 8px;
    padding-left: 20px;
    position: relative;
  }
  
  
  
  .showAndList li::before {
    content: "•";
    color: #3498db;
    font-weight: bold;
    position: absolute;
    left: 0;
  }
  
  /* Scrollbar Styles */
  .terms-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .terms-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .terms-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .terms-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }