.lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  