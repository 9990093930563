.tpu__myProfile__wrapper {
  .tpu__myProfile__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    .tpu__myProfile__section__content {
      padding: 10px;
      .tpu__myProfile__header {
        text-align: center;
        img {
          width: 70px;
          height: 70px;
          border-radius: 100px;
          object-fit: contain;
        }
        .profile__name {
          font-size: 16px;
          font-weight: 600 !important;
          color: #000;
          font-family: $font-family;
          margin-top: 40px !important;
        }
        .profile__username {
          font-size: 13px;
          font-weight: 500 !important;
          color: #000;
          font-family: $font-family;
          margin: 2px 0 !important;
        }
        .profile__status {
          background: #82cd47;
          font-family: $font-family;
          font-size: 12px;
          padding: 2px 8px;
          border-radius: 5px;
          color: #fff;
        }
      }
      .tpu__myProfile__info {
        .tpu__myProfile__info__group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid lightgray;
          padding: 10px;
          .info__group__img__name {
            display: flex;
            align-items: center;
            gap: 15px;
            svg {
              color: #0d6efd;
              font-size: 20px;
            }
            .info__group__name {
              h2 {
                color: #575757;
                font-weight: 600 !important;
                font-size: 14px;
              }
              p {
                color: #656b71;
                font-size: 13px;
              }
            }
          }
          .info__group__arrow {
            display: flex;
            align-items: center;
            gap: 5px;
            svg {
              color: #575757;
              margin-bottom: -5px;
            }
          }
        }
      }
    }
  }
}
