.tpu__header__section {
  padding: 20px 15px;
  .tpu__header__widgets {
    display: flex;
    justify-content: space-between;
    .leftArrowIcon {
      user-select: none;

      svg {
        color: $white-bg-color;
        font-family: $font-family;
        cursor: pointer;
      }
    }
    .tpu__header__name {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2px;
      h2 {
        color: $white-bg-color;
        font-family: $font-family;
        font-size: 18px;
        font-weight: 600 !important;
      }
      p {
        font-size: 11px;
        font-family: $font-family;
        font-weight: 400 !important;
      }
    }
    .questionIcon {
      svg {
        font-size: 20px;
        font-family: $font-family;
        color: $white-bg-color;
      }
    }
  }
}
