.tpu__emailLoginScreen__wrapper {
  background: #0b59f9;
  position: relative;

  .tpu__emailLoginScreen__bottomSheet__content {
    padding-top: 30px;
    .emailLoginForm {
      .emailLoginField {
        width: 100% !important;
        margin-bottom: 15px;
        display: flex;
        position: relative;
        .loginInput {
          border: none;
          width: 100%;
          outline: none;
          background: #f5f5f5;
          border-bottom: 1px solid #aaaaaa;
          padding: 7px 9px;
        }
        .eyeIcon {
          svg {
            position: absolute;
            right: 6px;
            top: 6px;
            font-size: 20px;
            cursor: pointer;
            user-select: none;
          }
        }
      }
      .forgotPassword {
        color: #0b59f9 !important;
        font-family: $font-family;
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        font-weight: 700 !important;
        margin-top: 5px !important;
      }
    }

    .submitButton {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 25px;
      .loginButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        background: #3871ff;
        border: 1px solid #3871ff;
        outline: none;
        padding: 8px 10px;
        border-radius: 5px;
        span {
          color: #fff;
          font-weight: 600 !important;
          font-family: $font-family;
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }
  }
}
