.tpu__leaderPointHistory__wrapper {
  .tpu__leaderPointHistory__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;

    .points-overview {
      padding: 1rem;
      background-color: rgb(255, 255, 255);
      border-radius: 30px;

      &__title {
        font-size: 1.4rem;
        font-weight: 700 !important;
        color: #1f2937;
        margin-bottom: 1rem !important;
      }

      &__card {
        background-color: #f2fbf3;
        border-radius: 1rem;
        padding: 1rem;
        margin-bottom: 0.75rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-left {
          display: flex;
          align-items: center;
          gap: 0.75rem;
        }

        &-icon {
          font-size: 16px !important; 
          border-radius: 0.5rem;

          svg {
            width: 1.5rem;
            height: 1.5rem;
            color: white;
          }
        }

        &-info {
          &-label {
            font-size: 0.800rem;
            font-weight: bold !important;
            color: #000000;
            margin-bottom: 0.3rem !important;
          }

          &-value {
            font-size: 1.2rem;
            font-weight: 700 !important;
            color: #2563eb;
          }
        }
      }

      &__timer {
        background-color: #2563eb;
        color: white;
        padding: 0.25rem 0.75rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &-emoji {
          font-size: 1.125rem;
        }
      }

      &__redeem-button {
        background-color: #2563eb;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #1d4ed8;
        }

        &-star {
          color: #fde047;
        }
      }

      &__earn-more {
        background-color: #e2fae5;
        margin-top: 2rem;

        &-content {
          color: #2563eb;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        &-arrow {
          font-size: 1.25rem;
        }
      }
    }

    // Animations
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    .animate-pulse {
      animation: pulse 2s infinite;
    }
    //Old Styling
    .tpu__leaderPointHistory__card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      .tpu__leaderPointHistory__card__box {
        border: 1px solid lightgray;
        padding: 5px;
        width: 48%;
        height: 110px;
        background: #e4f6ea;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tpu__leaderPointHistory__card__content {
          text-align: center;
          img {
            width: 70px;
            height: 70px;
          }
          .claim__now {
            font-size: 14px;
            font-family: $font-family;
            font-weight: 600 !important;
            color: #2f68fd;
          }
          .point__count {
            font-size: 14px;
            font-family: $font-family;
            font-weight: 600 !important;
            color: #000;
          }
          h2 {
            font-size: 16px;
            font-weight: 600 !important;
            font-family: $font-family;
          }
        }
      }
    }
    .tpu__leaderPointHistory__cards {
      padding: 10px;
      .tpu__leaderPointHistory__card__headerInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headerInfo__title {
          h2 {
            font-family: $font-family;
            font-size: 18px;
            color: #000;
            font-weight: 600 !important;
          }
        }
        .headerInfo__type {
          select {
            padding: 5px;
            outline: none;
            border: 1px solid lightgray;
            color: #000;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 600 !important;
          }
        }
      }
      .tpu__leaderPointHistory__card__container {
        .tpu__leaderPointHistory__Info {
          padding: 15px 0;
          .tpu__leaderPointHistory__tabHistory {
            margin-bottom: 5px;
            background: #eee;
            border-radius: 10px;
            padding: 10px;
            margin: 10px 0;
            .tpu__leaderPointHistory__tabHistory__heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              cursor: pointer;
              user-select: none;
              .tpu__leaderPointHistory__tabHistory__icon {
                display: flex;
                align-items: center;
                gap: 10px;
                .tpu__leaderPointHistory__tabIcon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 100px;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                .tpu__leaderPointHistory__tabName {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  h2 {
                    font-size: 13px;
                    font-family: $font-family;
                    font-weight: 600 !important;
                  }
                  span {
                    background: #1daf33;
                    padding: 1px 5px;
                    border-radius: 5px;
                    color: #fff;
                    width: max-content;
                    font-size: 11px;
                    font-family: $font-family;
                  }
                }
              }
              .tpu__leaderPointHistory__tabHistory__amount {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                position: relative;
                .tpu__leaderPointHistory__tabPrice {
                  text-align: end;
                  h3 {
                    font-size: 13px;
                    font-family: $font-family;
                    font-weight: 600 !important;
                  }
                  p {
                    color: gray;
                    font-size: 12px;
                    font-family: $font-family;
                  }
                  .claim__badge {
                    position: absolute;
                    background: #2f68fd;
                    top: -20px;
                    right: 0;
                    padding: 3px 5px;
                    color: #fff;
                    font-size: 11px;
                    border-radius: 5px;
                    font-family: $font-family;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
