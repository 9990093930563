.tpu__dashboard__bottomMenu {
  // padding: 5px;
  // background: #2f68fd;
  position: sticky;
  bottom: 0px;
  right: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 99;
  .tpu__dashboard__bottomMenu__widgets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    position: relative;
    .red__circle {
      position: absolute;
      width: 50px;
      height: 50px;
      z-index: 9;
      background: #2f68fd;
      color: #fff;
      top: -85%;
      border-radius: 100px;
      border: 5px solid #fff;
      transition: all 0.3s ease-in;
    }
    .tpu__dashboard__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
   
      width: calc(100% / 5);
      cursor: pointer;
      margin: auto;
      .tpu__menu__icon {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9;
        // transition: all 0.3s ease-in-out;
        svg {
          color: #fff;
          font-size: 25px;
        }
      }
      .tpu__menu__name {
        color: #fff;
        font-size: 10px;
        margin-top: 13px !important;
        // opacity: 0;
        // display: none;
        transition: all 0.3s ease-in;
      }
    }
  }
}
