.tpu__withdraw__wrapper {
  .tpu__withdraw__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    background: #edeffb;
    .tpu__withdraw__section__form {
      margin: 10px;
      background: #fff;
      border-radius: 15px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 15px;
      .tpu__withdraw__amount {
        text-align: center;
        width: 195px;
        margin: auto;
        .withdraw__amount {
          background: #edeffb;
          padding: 10px;
          border: 1px solid lightgray;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          font-family: $font-family;
          font-size: 11px;
          font-weight: 600 !important;
        }
      }
      .tpu__withdraw__input__group {
        margin-top: 20px;
        position: relative;
        background: #edeffb;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #3f1c89;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        input {
          width: 100%;
          outline: none;
          border: none;
          background: #edeffb;
          text-align: center;
          padding: 10px;
          font-family: $font-family;
          font-size: 20px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          font-weight: 600 !important;
        }
        span {
          background: tomato;
          margin-right: 10px;
          border-radius: 7px;
          padding: 8px 12px;
          font-family: $font-family;
          font-weight: 500 !important;
          color: #fff;
          font-size: 13px;
          cursor: pointer;
          user-select: none;
        }
      }
      .error__msg {
        color: tomato;
        font-size: 13px;
        text-align: start;
        margin-top: 10px !important;
      }
    }
    .tpu__withdraw__min__amount__msg {
      text-align: center;
      margin-top: 20px !important;
      span {
        background: #2f68fd;
        color: #fff;
        padding: 7px 10px;
        border-radius: 100px;
        font-family: $font-family;
        font-weight: 600 !important;
        font-size: 13px;
      }
    }
    .tpu__withdraw__history__btn {
      text-align: center;
      margin-top: 20px !important;
      span {
        background: #ec5120;
        color: #fff;
        padding: 7px 10px;
        border-radius: 5px;
        font-family: $font-family;
        font-weight: 600 !important;
        font-size: 11px;
        border: 1px solid gray;
        user-select: none;
        cursor: pointer;
        text-transform: capitalize;
      }
    }
    // Withdraw History And withdraw card
    .tpu__withdraw__history__card {
      margin: 10px !important;
      margin-top: 20px !important;
      .tpu__withdrawHistory__tabHistory {
        margin-bottom: 5px;
        background: #fff;
        border-radius: 10px;
        padding: 5px;
        .tpu__withdrawHistory__tabHistory__heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          cursor: pointer;
          user-select: none;
          .tpu__withdrawHistory__tabHistory__icon {
            display: flex;
            align-items: center;
            gap: 10px;
            .tpu__withdrawHistory__tabIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 50px;
              border-radius: 100px;
              border: 1px solid lightgray;
              background: #e3e6f5;
              svg {
                width: 30px;
                height: 30px;
                color: #4911a1;
              }
            }
            .tpu__withdrawHistory__tabName {
              display: flex;
              flex-direction: column;
              gap: 2px;
              h2 {
                font-size: 13px;
                font-family: $font-family;
                font-weight: 600 !important;
              }
              p {
                font-size: 11px;
                margin-bottom: 5px !important;
              }
              span {
                background: #1daf33;
                padding: 2px 5px;
                border-radius: 5px;
                color: #fff;
                width: max-content;
                font-size: 11px;
                font-family: $font-family;
              }
            }
          }
          .tpu__withdrawHistory__tabHistory__amount {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            .tpu__withdrawHistory__tabPrice {
              text-align: end;
              h3 {
                font-size: 13px;
                font-family: $font-family;
                font-weight: 600 !important;
              }
              p {
                color: gray;
                font-size: 12px;
                font-family: $font-family;
                // font-weight: 300 !important;
              }
            }
            .tpu__icon {
              span {
                svg {
                  font-size: 20px;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .tpu___withdrawHistory__tabHistory__details {
          padding: 10px 0;
          // display: none;
          .tpu__withdrawHistory__tabHistoryDetails__heading {
            text-align: center;
            border-bottom: 1px dashed lightgray;
            padding-bottom: 10px;
            .tpu__withdrawHistory__tabIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 50px;
              border-radius: 100px;
              border: 1px solid lightgray;
              margin: auto;
              background: #e3e6f5;
              margin-bottom: 5px !important;
              svg {
                width: 30px;
                height: 30px;
                color: #4911a1;
              }
            }
            .tpu__withdrawHistory__details__price {
              h2 {
                font-size: 14px;
                color: #000;
                font-weight: 600 !important;
                font-family: $font-family;
              }
              p {
                color: gray;
                font-size: 14px;
                font-family: $font-family;
              }
            }
          }

          .tpu__withdrawHistory__tabHistory___content {
            .tpu__tabHistory__content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid lightgray;
              padding: 8px 5px;
              font-family: $font-family;
              font-size: 13px;
              span {
                color: gray;
              }
              strong {
                font-weight: 600 !important;
                text-align: end;
              }
            }
          }
        }
      }
    }
    // Withdraw Card
    .tpu__withdraw__card__wrapper {
      //   margin: 10px;
      .tpu__withdraw__card__inner {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        .tpu__card__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          border-radius: 100px;
          background: #e3e6f5;
          img {
            width: 30px;
            height: 30px;
            color: #4911a1;
          }
        }
        .tpu__withdraw__bankInfo {
          padding-bottom: 20px;
          border-bottom: 2px dashed lightgray;
          .bank__name {
            margin-top: 10px;
            h2 {
              color: #000;
              font-size: 18px;
              font-weight: 600 !important;
            }
          }
          .bank__account {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 5px;
            span {
              margin-right: 5px;
              svg {
                color: gray;
              }
            }
            p {
              font-size: 12px;
              color: gray;
            }
          }
          .withdraw__fees {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: gray;
            span {
              display: flex;
              align-items: center;
              font-family: $font-family;
              font-size: 14px;
              svg {
                margin-bottom: -3px;
                margin-left: 7px;
                font-size: 18px;
                color: gray;
              }
            }
            p {
              font-size: 14px;
            }
          }
        }
        .tpu__withdraw__button {
          padding-top: 15px;
          button {
            width: 100%;
            outline: none;
            padding: 5px;
            border-radius: 100px;
            border: 2px solid #462d7c;
            background: transparent;
            font-family: $font-family;
            font-weight: 600 !important;
            font-size: 15spx;
          }
        }
      }
    }
  }
}
