.header-container {
  background-color: #fff;
  padding: 5px 15px;
  /* z-index: 99; */
  position: relative;
}

.header-main-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-size: 16px;
  font-weight: 700 !important;
  color: #000;
}

.header-actions {
  display: flex;
  align-items: center;
}

.points-button {
  background-color: #fff;
  padding: 1px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  gap: 3px;
}

.points-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}

.points-text {
  color: #FF0000;
  font-size: 12px;
  font-weight: 700;
}

.wallet-button {
  padding: 4px 10px;
  border: 1px solid lightgray;
  border-radius: 15px;
  margin-right: 5px;
  background-color: #e4f6ea;
  color: #42a755;
  font-size: 13px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.gift-button {
  padding: 4px 10px;
  border: 1px solid lightgray;
  border-radius: 15px;
  margin-right: 10px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.gift-button-content {
  color: #575757;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gift-button-text {
  color: #575757;
  font-size: 14px;
}

.profile-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px;
  min-width: 16px;
  height: 16px;
  font-size: 8px;
  font-weight: bolder !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1.5px solid #fff !important;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

button {
  border: none;
  outline: none;
}

button:hover {
  opacity: 0.9;
}

.header-profile-progress {
  position: relative;
  width: 42px;
  height: 42px;
}

.header-progress-tooltip {
  position: absolute;
  right: 7px !important;
  top: -8px !important;
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
  width: 17px !important;
  height: 17px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px;
  font-weight: bolder;
  border: 2px solid white;
  z-index: 2;
}

/* Override circular progressbar default styles */
.CircularProgressbar {
  width: 100%;
  height: 100%;
}

.CircularProgressbar-path {
  stroke-linecap: round;
}

@media (max-width: 480px) {
  .header-container {
    padding: 5px 10px;
  }

  .header-title {
    font-size: 12px;
  }

  .points-icon {
    width: 18px;
    height: 18px;
  }

  .points-text {
    font-size: 11px;
    margin-left: 3px;
  }

  .wallet-button {
    font-size: 11px;
    padding: 5px 8px;
  }

  .header-profile-progress {
    width: 35px;
    height: 35px;
  }

  .profile-button {
    width: 30px;
    height: 30px;
  }

  .header-progress-tooltip {
    width: 18px;
    height: 18px;
    font-size: 6px;
    right: -6px;
    top: -6px;
  }
}