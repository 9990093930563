.kyc-wrapper {
  background: rgb(253, 253, 253);

  .kyc-section-area {
    height: calc(100vh - 108px);
    overflow-y: auto;

    .kyc-form {
      .form-group {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
        }

        select,
        input {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }

      .upload-group {
        display: flex;
        margin: 10px;
        gap: 15px;
      }

      .upload-item {
        flex: 1;

        label {
          display: block;
          margin-bottom: 5px;
        }

        .file-upload {
          display: flex;
          align-items: center;
          margin-top: 5px;
        }

        .upload-button {
          display: inline-block;
          padding: 2px 2px;
          background-color: #dededea2;
          border: 1px solid rgba(0, 0, 0, 0.175);
          border-radius: 4px;
          cursor: pointer;
        }

        .file-input {
          display: none; /* Hide the actual file input */
        }

        .file-status {
          margin-left: 10px;
        }
      }
      .submit-btn-section {
        display: flex;
        justify-content: center;
        .submit-btn {
          padding: 7px;
          font-size: 1.2em;
          width: 95%;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .view-kyc-section {
      margin-top: 20px;
      color: rgb(132, 0, 144);
      border: none;
      display: flex;
      justify-content: center;

      .view-kyc-section-text {
        font-size: large;
      }
    }

    .kyc-card {
      margin: 10px;
      margin-bottom: 0px;
      background-color: #f0f9f4;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .profile-section {
        display: flex;
        align-items: center;

        .profile-picture {
          margin-right: 10px;
          margin-right: 10px;

          img {
            border-radius: 50%;
            border: 2px solid #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
        }

        .user-info {
          .username {
            font-size: 16px;
            font-weight: bolder !important;
            margin: 0;
            color: #333;
          }

          .kyc-method {
            font-weight: bold !important;
            font-size: 14px;
            color: #848484;
            margin-top: 3px !important;
            margin-bottom: 5px !important;
          }

          .blank {
            margin-left: 2px;
            padding-left: 65px;
            background-color: white;
            border: 1px solid rgb(204, 204, 204);
            border-radius: 4px;
          }
        }
      }

      .kyc-info {
        text-align: right;
        margin-left: auto;

        .status {
          font-size: 14px;
          font-weight: bold;
          color: #4caf50;
          text-transform: capitalize;
        }

        .card-number {
          font-size: 16px;
          font-weight: bold;
          color: #848484;
          margin: 5px 0;
          margin-top: 3px !important;
        }

        .date {
          font-size: 12px;
          color: #666;
          margin-top: 5px !important;
        }
      }
      .dropdown {
        border: none;
        background: none;
        font-size: 2.2em;
        cursor: pointer;
        margin: 0;
        margin-top: auto;
      }
    }
    .kyc-images {
      margin: 10px;
      margin-top: 0px;
      border: 1px solid #ccc;
      border-radius: 14px;
      padding-top: 10px;
      padding-bottom: 10px;

      .kyc-image-heading{
        margin: 10px;
        margin-left: 25px;
        margin-right: 25px;
        border-radius: 6px;
        padding: 2px;
        text-align: center;
        background-color: black;
        color: white;
      }

      .kyc-image {
        margin-top: 10px;
        margin-left: 60px;
        margin-right: 60px;

        p {
          font-weight: bold;
          margin-bottom: 5px;
        }

        img {
          max-width: 100%;
          height: auto;
          border: 1px solid #b3a7a7;
          border-radius: 4px;
        }
      }
    }

    .disclaimer-section {
      justify-content: center;
      margin-top: 20px;
      padding: 15px;
      border-radius: 10px;
    }

    .disclaimer-section ol {
      margin: 10px 0 0;
      padding-top: 5px;
      padding-left: 24px;
      background-color: #e3ffe5;
      border-radius: 10px;
    }

    .disclaimer-section li {
      padding: 5px;
      margin-bottom: 5px;
      font-size: 14px;
    }

    .disclaimer-section h4 {
      margin: 0;
      margin-left: 5px !important;
      color: #890086;
      font-size: 16px;
      font-weight: bold !important;
    }

    .disclaimer-icon {
      display: flex;
      align-items: center;
      color: #e65c00;
    }

    .disclaimer-li {
      border-bottom: 2px solid rgba(0, 0, 0, 0.093);
      width: 90%;
    }

    .disclaimer-li-last {
    }
  }
}
