
  .footer-container {
    width: 100%;
    background-color: #007bff;
    position: sticky;
    height: 58px;
    bottom: 0;
    z-index: 99;
    display: flex;
    padding-top: 15px;
    right: 0;
    left: 0;
  }
  
  .footer-item-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1 !important;
  }
  
  .footer-item:hover {
    opacity: 1 !important;
  }
  
  .footer-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: #f8f9fa; */
    border-radius: 50%;
  }
  
  .footer-icon-wrapper.selected {
    width: 50px;
    height: 50px;
    border: 5px solid #ffffff;
    background-color: #007bff;
    top: -38px;
  }
  
  .footer-item-before, .footer-item-after {
    position: absolute;
    bottom: 10px;
    width: 15px;
    height: 15px;
    /* background-color: #ffffff; */
  }
  
  .footer-item-before {
    left: -15px;
    border-bottom-right-radius: 15px;
  }
  
  .footer-item-after {
    right: -15px;
    border-bottom-left-radius: 15px;
  }
  
  .footer-item-text {
    font-size: 12px;
    font-weight: 500 !important;
    color: #fff;
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;
  }
  
  .footer-item:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }

  