.notification-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
  }
  
  .notification-modal {
    width: 425px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: none;
  }
  
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    position: relative;
  }
  
  .notification-header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #343a40;
    /* position: relative; */
  }
  
  .notification-modal-close-button,
  .notification-modal-clear-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    position: absolute;
    right: 20px;
    /* top: 60%; */
    transform: translateY(10%) translateX(10%);
  }
  
  .notification-modal-clear-button {
    margin-right: 40px !important;
    color: #007bff;
  }
  
  .notification-modal-close-button svg,
  .notification-modal-clear-button svg {
    fill: #6c757d;
    transition: fill 0.2s ease;
  }
  
  .notification-modal-close-button:hover svg,
  .notification-modal-clear-button:hover svg {
    fill: #343a40;
  }
  
  .notifications-content {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .notification-item {
    display: flex;
    align-items: flex-start;
    padding: 8px 16px;
    border-bottom: 1px solid #e9ecef;
    transition: background-color 0.2s ease, transform 0.3s ease, opacity 0.3s ease, height 0.3s ease, padding 0.3s ease, margin 0.3s ease;
    cursor: pointer;
    height: auto;
    overflow: hidden;
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }
  
  .notification-item:hover {
    background-color: #f8f9fa;
  }
  
  .notification-icon {
    margin-right: 16px;
    padding-top: 2px;
  }
  
  .notification-icon svg {
    fill: #007bff;
  }
  
  .notification-content {
    flex: 1;
  }
  
  .notification-message {
    font-size: 14px;
    color: #343a40;
    margin-bottom: 4px;
    line-height: 1.4;
  }
  
  .notification-date {
    font-size: 12px;
    color: #6c757d;
    text-align: right;
  }
  
  .no-notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    color: #6c757d;
  }
  
  .no-notifications svg {
    fill: #adb5bd;
    margin-bottom: 16px;
  }
  
  .no-notifications p {
    font-size: 16px;
    margin: 0;
  }
  
  .notification-item.swiped-left,
  .notification-item.swiped-right {
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  
  @media screen and (max-width: 768px) {
    .notification-modal {
      width: 350px;
      border: none;
    }
  }
  
  /* Custom scrollbar styles */
  .notifications-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .notifications-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .notifications-content::-webkit-scrollbar-thumb {
    background: #c1c9d0;
    border-radius: 3px;
  }
  
  .notifications-content::-webkit-scrollbar-thumb:hover {
    background: #a8b2bb;
  }
  
  