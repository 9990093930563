.tpu__mysteryBox__wrapper {
  .tpu__mysteryBox__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    padding: 10px;
    .tpu__mysteryBox__cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .tpu__mysteryBox__card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 13px -3px #c7c7c7;
        gap: 25px;
        .tpu__mysteryBox__leftSide {
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 100px;
            border: 1px solid lightgray;
            padding: 5px;
          }
          .tpu__mysteryBox__content {
            h2 {
              color: #000;
              font-weight: 600 !important;
              font-size: 14px;
              margin-bottom: 5px !important;
            }
            p {
              font-size: 13px;
              color: gray;
              font-weight: 600 !important;
            }
          }
        }
        .tpu__mysteryBox__rightSide {
          button {
            background: #ec5120;
            outline: none;
            cursor: pointer;
            border: 1px solid lightgray;
            padding: 5px 12px;
            border-radius: 5px;
            color: #fff;
            font-weight: 600 !important;
            font-family: $font-family;
            height: 28px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
