@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;600&display=swap');
.tpu__dashboard__wrapper {
  height: 100vh;
  .tpu__content__area {
    padding: 5px;
    padding-bottom: 30px;
    // min-height: 100vh;
    .tpu__wallet__section {
      .tpu__wallet__area {
        .tpu__wallet__widget {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .tpu__wallet__box {
            padding: 10px 10px 8px 15px;
            border: 1px solid #E0E0E0;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 10px;
            width: calc(100% / 3);
            .tpu__wallet__name {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: flex-start;
              gap: 5px;
              .name {
                font-size: 12px;
                color: gray;
              }
              .amount {
                font-size: 14px;
                font-weight: bold !important;
              }
            }
            .tpu__wallet__icon {
              span {
                svg {
                  margin-bottom: -5px !important;
                }
              }
            }
          }
        }
      }
    }
    // Slider Section
    .tpu__slider__section {
      height: 120px;
      margin-top: 15px !important;
      border-radius: 10px;
      box-shadow: 0 2px 3.8px rgba(0, 0, 0, 0.25);
      width: 97%;
      margin: auto;
    }
    // Social Media Task
    .tpu__socialMedia__task {
      padding-top: 20px;
      .tpu__socialMedia__header {
        h2 {
          font-size: 13px;
          color: #000;
          font-weight: 600 !important;
        }
      }
      .tpu__socialMedia__task__icon {
        margin-top: 10px;
        padding: 10px !important;
        border: 1px solid lightgray;
        border-radius: 10px;
        .tpu__socialMedia__task__box {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
          justify-content: center;
          gap: 20px;
          .tpu__socialMedia__icon {
            position: relative;
            text-align: center;
            border-radius: 10px;
            box-shadow: 0px 0px 5px 1px #e2e2e2;
            padding: 7px 0px;
            cursor: pointer;
            transition: all 0.15s ease;
            transform: translateY(0);
            
            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
            }
            
            &.active {
              transform: translateY(2px);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              opacity: 0.9;
            }
            .amount__badge {
              position: absolute;
              top: -5px;
              right: -5px;
              font-size: 10px;
              background: green;
              color: #fff;
              padding: 3px 7px;
              border-radius: 50px;
              font-family: $font-family !important;
            }
            .tpu__media__icon {
              img {
                width: 28px;
                height: 28px;
              }
            }
            .media__name {
              font-size: 14px;
              font-family: $font-family !important;
              margin-bottom: 5px !important;
              
            }
            .tasks__badge{
              background-color: tpu__socialMedia__icon;
              padding: 2px 3px;
              font-size: 9px;
              color: white;
              font-family: 'Roboto', sans-serif;
              border-radius: 7px;
              
            }
          }
        }
      }
    }
    //New Promotional Card
    .tpu__promotional__card {
      background: #FFFFFF;
      border-radius: 16px;
      padding: 16px;
      /* margin: 16px; */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      margin-top: 20px !important;
      width: 96%;
      margin: auto;
    }
    
    .tpu__promotional__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    
    .tpu__promotional__text {
      flex: 1;
      margin-right: 16px;
    }
    
    .tpu__promotional__text h2 {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 8px !important;
      font-family: "Roboto", sans-serif !important;
    }
    
    .tpu__promotional__text p {
      font-size: 13px;
      color: #666666;
      font-family: "Poppins", sans-serif !important;
    }
    
    .tpu__promotional__image img {
      width: 58px;
      height: 58px;
      border-radius: 24px;
      object-fit: cover;
    }
    
    .tpu__promotional__button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1877F2;
      padding: 12px;
      border-radius: 8px;
      text-decoration: none;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      // transition: background-color 0.3s ease;
      font-family: "Roboto", sans-serif !important;
      position: relative;
  transition: all 0.15s ease;
      transform: translateY(0);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .tpu__promotional__button:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
    
    .tpu__promotional__button.active {
      transform: translateY(2px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      opacity: 0.9;
    }
    
    .tpu__promotional__button span {
      margin-right: 8px;
    }
    
    .tpu__promotional__button svg {
      font-size: 18px;
    }

    // Share Refer Link
    .tpu__shareRefer__link__section {
      margin-top: 40px;
      padding: 18px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      
      .tpu__shareReferLink__header {
        margin-bottom: 16px !important;
        h2 {
          text-align: center;
          font-size: 18px;
          color: #333333;
          font-weight: 600 !important;
        }
      }
      .tpu__shareReferLink__icons {
        margin-top: 10px;
        .tpu__shareReferLink__box {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          justify-content: center;
          gap: 10px;
          .tpu__shareReferLink__widget {
            text-align: center;
            span {
              width: 40px;
              height: 40px;
              padding: 10px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid lightgray;
              margin: auto;
            }
            p {
              font-size: 11px;
              font-family: $font-family !important;
              font-weight: 500 !important;
              margin-top: 3px !important;
            }
          }
        }
      }
    }
    // Premium section
    .tpu__premium__section {
      margin-top: 20px;
      width: 96%;
      margin: 20px auto;
      .tpu__premium__section__boxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
        gap: 30px;
        .premium__box {
          cursor: pointer;
          text-align: center;
          border-radius: 8px;
          transition: transform 0.15s ease;
          
          &.active {
            transform: translateY(2px) !important;
          }
          
          .premium__box-inner {
            padding: 10px 0px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            background-color: white;
            transform: translateY(-2px);
            transition: all 0.15s ease;
            
            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 6px 8px rgba(0, 0, 0, 0.25);
            }
            
            span {
              svg {
                color: #2f68fd;
                font-size: 25px;
              }
            }
            p {
              margin-top: 10px !important;
              color: #000;
              font-size: 0.675rem;
              font-family: $font-family !important;
              font-weight: 600 !important;
            }
          }
        }
      }
    }
    // connected with us
    .tpu__connectedUs__section {
      margin-top: 40px !important;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      .tpu__shareReferLink__header {
        h2 {
          text-align: center;
          font-size: 17px;
          color: #000;
          font-weight: 600 !important;
        }
      }
      .tpu__shareReferLink__icons {
        .tpu__shareReferLink__box {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          justify-content: center;
          gap: 10px;
          .tpu__shareReferLink__widget {
            text-align: center;
            span {
              padding: 10px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg{
                font-size: 25px;
              }
            }
          }
        }
      }
    }
    // Dropdown section
    .tpu__dropdown__section {
      margin: 20px 10px;
      margin-bottom: 0 !important;
      
      .tpu__dropdown__header {
        margin-bottom: 10px;
        
        h2 {
          font-size: 16px;
          font-weight: 700;
          color: #1a1a1a;
        }
      }
      
      .tpu__dropdown__container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        
        .tpu__dropdown {
          width: 48%;
          height: 50px;
          background-color: #f8f9fa;
          border-radius: 12px;
          border: 1px solid #e9ecef;
          padding: 0 12px;
          font-size: 14px;
          font-weight: 600;
          color: #212529;
          cursor: pointer;
          
          &:focus {
            outline: none;
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
          }
        }
      }
    }
    .tpu__custom-dropdown {
      position: relative;
      width: 48%;
      
      .tpu__custom-dropdown-header {
        height: 45px;
        background-color: #f8f9fa;
        border-radius: 12px;
        border: 1px solid #e9ecef;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        
        &:hover {
          border-color: #80bdff;
        }
        
        .tpu__custom-dropdown-selected {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          
          span {
            font-size: 12px;
            font-weight: 600;
            color: #212529;
          }
          
          .placeholder {
            color: #6c757d;
          }
        }
        
        .arrow {
          font-size: 12px;
          color: #212529;
          margin-left: 8px;
        }
      }
      
      .tpu__custom-dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        border-radius: 12px;
        border: 1px solid #e9ecef;
        margin-top: 4px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        z-index: 1000;
        max-height: 250px;
        overflow-y: auto;
        
        .tpu__custom-dropdown-option {
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          
          &:hover {
            background-color: #f8f9fa;
          }
          
          &.selected {
            background-color: #e9ecef;
          }
          
          span {
            font-size: 14px;
            color: #212529;
          }
        }
      }
      
      .tpu__dropdown-icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
    .tpu__dropdown-option-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
