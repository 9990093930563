.tpu__dashboard__wrapper {
  .tpu__wallet__section__area {
    height: calc(100vh - 108px);
    padding: 5px;
    .tpu__wallet__section__card {
      .tpu__wallet__card {
        display: flex;
        justify-content: space-between;
        background: #4a65fc;
        padding: 15px;
        border-radius: 10px;
        height: 170px;
        position: relative;
        .tpu__wallet__card__upper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .wallet__title {
            font-size: 18px;
            font-family: $font-family;
            color: #fff;
          }
          .wallet__amount {
            font-size: 13px;
            font-family: $font-family;
            color: #fff;
            span {
              background: #45bbff;
              padding: 2px 3px;
              border-radius: 5px;
              margin-right: 5px;
            }
          }
        }
        .tpu__wallet__card__img {
          img {
            width: 28px;
            height: 28px;
          }
        }
        .wallet__badge {
          position: absolute;
          font-family: $font-family;
          bottom: 15px;
          right: 15px;
          color: #fff;
          font-size: 13px;
        }
      }
    }
    // Wallet Information
    .tpu__wallet__information__section {
      padding-top: 10px;
      .tpu__wallet__heading {
        h2 {
          font-size: 16px;
          font-family: $font-family;
          font-weight: 600 !important;
          text-transform: capitalize;
        }
      }
      .tpu__wallet__information__content {
        margin-top: 10px;
        .tpu__wallet__information__cards {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .tpu__wallet__information__card {
            display: flex;
            align-items: center;
            gap: 10px;
            .tpu__wallet__information__icon {
              .tpu__wallet__information__iconArea {
                border: 1px solid lightgray;
                padding: 10px;
                width: 50px;
                height: 50px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
          .tpu__wallet__information__amount__card {
            width: 100%;
            .tpu__wallet__information__amount {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid lightgray;
              padding: 8px 10px;
              border-radius: 5px;
              .tpu__wallet__title {
                display: flex;
                flex-direction: column;
                gap: 5px;
                h2 {
                  font-size: 16px;
                  color: #000;
                  font-family: $font-family;
                  font-weight: 600 !important;
                }
                p {
                  color: #1e48da;
                  font-size: 12px;
                }
              }
              .tpu__wallet__withdrawBtn {
                span {
                  user-select: none;
                  cursor: pointer;
                  color: #e6304c;
                  border: 1px solid #e6304c;
                  font-family: $font-family;
                  font-size: 13px;
                  padding: 5px 8px;
                  border-radius: 100px;
                }

                span:hover {
                  background: #e6304c;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
    // Services Section
    .tpu_service_section {
      padding-top: 10px;
      .tpu__services__heading {
        h2 {
          font-size: 16px;
          font-family: $font-family;
          font-weight: 600 !important;
          text-transform: capitalize;
        }
      }
      .tpu__services__cards {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .tpu__services__card {
            user-select: none;
            cursor: pointer;
          .services__card {
            background: #ee5361;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 14px;
            border-radius: 5px;
            span {
              svg {
                color: #fff;
                font-size: 30px;
              }
            }
          }
          .service__name{
            font-size: 14px;
            font-family: $font-family;
            font-weight: 600 !important;
            text-align: center;
          }
        }
      }
    }
  }
}
