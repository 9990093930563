@import "../abstract/variables";
.materialTablePagination {
  .MuiTablePagination-toolbar {
    transition: all 0.3s ease-in-out;
    color: #000 !important;
    .MuiTablePagination-selectLabel,
    .MuiInputBase-colorPrimary {
      display: none !important;
    }
    .MuiTablePagination-actions {
      button {
        transition: all 0.3s ease-in-out;
        color: #000 !important;
      }
      .Mui-disabled {
        color: gray !important;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.materialTable {
  transition: all 0.3s ease-in-out;
  .materialTableHead {
    .materialTableRow {
      .materialTableCell {
        background: #fff !important;
        color: #000 !important;
        font-size: 13px;
        border-bottom: 1px solid rgb(224 224 224 / 38%) !important;
        text-align: center !important;
        white-space: nowrap;

        @include breakpoints(tablet) {
          padding: 5px !important;
          font-size: 12px !important;
        }
      }
    }
  }
  .materialTableBody {
    .react-datepicker-wrapper
      > .react-datepicker__input-container
      > .followUpDate {
      text-align: center;
      width: 100px;
    }
    .react-datepicker-popper {
      z-index: 100;
    }
    .materialTableBodyRow {
      .materialTableBodyCell {
        font-size: 13px;
        color: #000 !important;
        border-bottom: 1px solid rgb(224 224 224 / 38%) !important;
        text-align: center !important;

        @include breakpoints(tablet) {
          padding: 5px !important;
          font-size: 12px !important;
        }
        @include breakpoints(extralargeMobile) {
          padding: 8px 5px !important;
          font-size: 12px !important;
          white-space: nowrap !important;
        }
      }
    }
  }
}

//
