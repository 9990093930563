@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #3a0ca3;
  --secondary-color: #4361ee;
  --accent-color: #4cc9f0;
  --background-color: #f0f4f8;
  --text-color: #2b2d42;
  --card-background: #ffffff;
}

.careers-page {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.careers-container {
  max-width: 450px;
  width: 100%;
  padding: 0 1rem;
}

.careers-header-wrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.careers-header {
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.careers-header::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
}

.career-vacancies {
  display: grid;
  gap: 1.5rem;
}

.career-card {
  background-color: var(--card-background);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.career-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.career-card-header {
  background-color: var(--primary-color);
  padding: 1rem;
}

.career-title {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.career-card-body {
  padding: 1rem;
}

.career-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.career-detail-item {
  display: flex;
  align-items: center;
}

.career-icon {
  color: var(--secondary-color);
  margin-right: 0.5rem;
}

.career-detail-text {
  font-size: 0.9rem;
  color: var(--text-color);
}

.career-description {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.career-apply-btn {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-apply-btn:hover {
  background-color: var(--primary-color);
}

.apply-icon {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.join-us {
  text-align: center;
  margin-top: 2rem;
}

.join-us-btn {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.join-us-btn:hover {
  background-color: var(--primary-color);
  transform: scale(1.05);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.career-card {
  animation: fadeInUp 0.5s ease-out forwards;
  opacity: 0;
}

.career-card:nth-child(1) { animation-delay: 0.1s; }
.career-card:nth-child(2) { animation-delay: 0.2s; }
.career-card:nth-child(3) { animation-delay: 0.3s; }
.career-card:nth-child(4) { animation-delay: 0.4s; }
.career-card:nth-child(5) { animation-delay: 0.5s; }
.career-card:nth-child(6) { animation-delay: 0.6s; }

@media (max-width: 400px) {
  .careers-header {
    font-size: 1.75rem;
  }

  .career-title {
    font-size: 1.1rem;
  }

  .career-detail-text,
  .career-description,
  .career-apply-btn {
    font-size: 0.85rem;
  }
}