@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "../abstract/fonts";
body {
  position: relative;
  width: 100%;
}

.layoutContainer {
  width: 425px;
  max-width: 100%;
  margin: auto;
  height: auto;
  min-height: 100vh;
}
:disabled {
  background: gray !important;
  border: 1px solid gray !important;
  color: white !important;
  cursor: not-allowed;
}

// Slider
.awssld__bullets {
  bottom: -25px !important;
}
.awssld__bullets button {
  width: 9px !important;
  height: 9px !important;
  background: lightgray !important;
}
.awssld__bullets .awssld__bullets--active {
  background: #2f68fd !important;
  transform: none !important;
}
.awssld__content {
  border-radius: 10px !important;
}

// React Tabs
.react-tabs {
  width: 100%;
}
.react-tabs__tab-list {
  padding: 2px !important;
  width: 100% !important;
  border: 1px solid #ff9f29;
  border-radius: 100px;
  background: #fff;
}
.react-tabs__tab--selected {
  background: #ff9f29 !important;
  border-color: #ff9f29 !important;
  color: #fff !important;
  border-radius: 100px !important;
  outline: none !important;
}
.react-tabs__tab {
  width: calc(100% / 3);
  text-align: center;
  bottom: 0 !important;
  font-family: $font-family, "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 600 !important;
}
.react-tabs__tab:focus:after {
  background: transparent !important;
}
