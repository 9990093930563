@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;600&display=swap");
.tpu__socialMedia__task__wrapper {
  .tpu__socialMediaTask__section__area {
    padding: 10px;
    height: calc(100vh - 108px);
    overflow-y: auto;
    scrollbar-width: none;
    .tpu__socialMediaTask__heading {
      padding-bottom: 10px;
      h2 {
        font-size: 18px;
        font-family: $font-family;
        font-weight: 600 !important;
        text-align: center;
      }
    }
    .tpu__socialMediaTask__tasks {
      
      display: flex;
      flex-direction: column;
      gap: 35px;
      .tpu__socialMediaTask__card {
        position: relative;
        cursor: pointer;
        user-select: none;
        font-family: "Roboto", sans-serif;
        .amount__badge {
          position: absolute;
          top:-10px;
          right: 50px;
          font-size: 10px;
          font-family: "Roboto", sans-serif;
          background: green;
          color: #fff;
          padding: 3px 7px;
          border-radius: 50px;
          
        }
        .amount__badge__tasks {
          position: absolute;
          top:110px;
          right: 0;
          font-size: 10px;
          background: rgb(1, 86, 255);
          color: #fff;
          padding: 3px 7px;
          border-radius: 50px;
          font-family: "Roboto", sans-serif;
        }
        .tpu__socialMediaTask__img {
          height: 120px;
          box-shadow: 0px 0px 14px -6px gray;
          border-radius: 10px;
          img {
            width: 100%;
            height: 120px;
            border-radius: 10px;
          }
        }
        .tpu__socialMedia__task__button {
          text-align: center;
          button {
            position: absolute;
            top: 117px; 
            right: 150px;
            border: none;
            border-top: solid 2px rgb(196, 196, 196);
            padding: 4px 15px;
            color: #fff;
            outline: none;
            cursor: pointer;
            border-radius: 5px;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            
          }
        }
      }
    }
  }
}
