.tpu__leaderBoard__wrapper {
  .tpu__leaderBoard__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    .tpu__leaderBoard__tab__area {
      background: #fff;
      margin-top: 10px;
      padding: 5px;
      height: 100%;
      padding-top: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .tpu__leaderBoard__tabs {
        .tpu__leaderBoard__tab__content {
          margin-top: 5px;
          .tpu__leaderBoard__tab__history {
            padding: 5px;
            .tpu__leaderBoard__tab__heading {
              display: flex;
              justify-content: space-between;
              .tpu__leaderBoard__tab__mysteryBox {
                .mysteryBox-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  cursor: pointer;
                }
                
                /* Replace with a more reliable bounce animation */
                .floating-element {
                  animation: bounce 0.8s cubic-bezier(0.28, 0.84, 0.42, 1) infinite alternate;
                }
                
                @keyframes bounce {
                  0% {
                    transform: translateY(0);
                  }
                  100% {
                    transform: translateY(-15px);
                  }
                }
                
                /* Blinking animation remains the same */
                .blinking-element {
                  animation: blinking 2s ease-in-out infinite;
                }
                
                @keyframes blinking {
                  0% {
                    opacity: 1;
                  }
                  50% {
                    opacity: 0.6;
                  }
                  100% {
                    opacity: 1;
                  }
                }
                
                img {
                  width: 60px;
                  height: 60px;
                }
                
                .rank-up-text {
                  color: #fff;
                  background: #1076E2;
                  padding: 2px 10px;
                  border-radius: 20px;
                  font-weight: 600 !important;
                  font-size: 10px;
                  margin-top: -5px ;
                  font-family: Poppins;
                }
              }
              .tpu__leaderBoard__tab__rankLeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                .tpu__leaderBoard__tab__leader {
                  text-align: center;
                  position: absolute;
                  .rankCount {
                    color: #42c57b;
                    font-size: 13px;
                    font-weight: 600 !important;
                  }
                  .rankDownIcon {
                    svg {
                      color: gray;
                      font-size: 25px;
                    }
                  }
                  .tpu__leaderBoard__profile {
                    img {
                      width: 80px;
                      height: 80px;
                      border-radius: 100px;
                      border: 1px solid lightgray;
                    }
                  }
                  .tpu__leaderBoard__name {
                    .name {
                      color: #000;
                      font-size: 13px;
                      font-weight: 600 !important;
                      white-space: nowrap;
                    }
                    .point {
                      color: #42c57b;
                      font-size: 13px;
                      font-weight: 600 !important;
                    }
                    .price {
                      color: #ff9f2b;
                      font-size: 11px;
                      font-weight: 700 !important;
                    }
                  }
                }
              }
              .tpu__leaderBoard__tab__icon {
                span {
                  svg {
                    font-size: 50px;
                    color: #42c57b;
                    margin-top: 6px;
                  }
                }
              }
            }
            .tpu__leaderBoard__tab__timer {
              color: #cd373d;
              display: flex;
              align-items: center;
              gap: 5px;
              margin-top: 54%;
              justify-content: center;
              svg,
              p {
                font-weight: 500 !important;
                font-family: $font-family;
              }
            }
            .tpu__leaderBoard__tab__cards {
              margin-top: 10px;
              .tpu__leaderBoard__tab__card {
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px 1px 4px -1px #bbbbbb;
                border-radius: 100px;
                padding-right: 15px;
                margin-bottom: 8px;
                .tpu__leaderBoard__card__profile {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  flex-basis: 42%;
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                  }
                  h2 {
                    font-size: 13px;
                    color: #000;
                    font-weight: 600 !important;
                  }
                  p {
                    color: gray;
                    font-size: 12px;
                  }
                }
                .tpu__leaderBoard__card__point {
                  flex-basis: 30%;
                  p {
                    color: #ff9f2b;
                    font-size: 12px;
                    font-weight: 600 !important;
                  }
                }
                .tpu__leaderBoard__card__price {
                  display: flex;
                  align-items: center;
                  gap: 3px;
                  p {
                    color: #42c57b;
                    font-size: 12px;
                    font-weight: 600 !important;
                  }
                }
              }
              .tpu__leaderBoard__tab__floating__card{
                background: #e0aa3e;
                position: sticky;
                bottom: 15px;
                width: 100%;
                h2, p{
                    color: #fff !important;
                }
              }
            }
          }
        }
        
        > .tpu__leaderBoard__pagination_div {
          margin-bottom: 20px !important;
          padding-bottom: 20px !important;
        }
      }
    }
  }
}
