$primary-color: #2563EB;
$secondary-color: #2563EB;
$background-color: #f5f7fa;
$text-color: #333;
$border-color: #e0e0e0;

.tpu__redeemCoupon__section__wrapper {
  // background-color: $background-color;
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;

  .layoutContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $background-color;
    min-height: 100vh;
  }

  .tpu__redeemCoupon__section__area {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .tpu__redeemCoupon__form {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 30px;
      width: 100%;
      max-width: 400px;

      .tpu__redeemCoupon__title {
        text-align: center;
        margin-bottom: 24px;

        h2 {
          color: $text-color;
          font-weight: 600;
          font-size: 24px;
          margin: 0;
        }
      }

      .tpu__redeemCoupon__group {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .floating-label-group {
          position: relative;
          
          input {
            width: 100%;
            border: 2px solid $border-color;
            padding: 12px;
            border-radius: 8px;
            font-size: 16px;
            transition: all 0.3s ease;
            outline: none;
            background: transparent;
            
            &:focus {
              border-color: $primary-color;
            }

            &:focus ~ label,
            &:not(:placeholder-shown) ~ label {
              transform: translateY(-24px) scale(0.85);
              color: $primary-color;
              background-color: #ffffff;
              padding: 0 6px;
            }
          }

          label {
            position: absolute;
            left: 12px;
            top: 45%;
            transform: translateY(-50%);
            color: #999;
            pointer-events: none;
            transition: all 0.3s ease;
            transform-origin: left top;
            background-color: transparent;
            padding: 0 6px;
          }
        }
      }

      button {
        width: 100%;
        padding: 12px;
        background-color: $primary-color;
        color: #ffffff;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.1s ease, all 0.15s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          background-color: $secondary-color;
        }

        &:disabled {
          background-color: #bdc3c7;
          cursor: not-allowed;
        }

        &.pressed {
          transform: translateY(2px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          opacity: 0.9;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .tpu__redeemCoupon__section__wrapper {
    .tpu__redeemCoupon__section__area {
      .tpu__redeemCoupon__form {
        padding: 20px;

        .tpu__redeemCoupon__title {
          h2 {
            font-size: 20px;
          }
        }

        .tpu__redeemCoupon__group {
          input {
            font-size: 14px;
          }
        }

        button {
          font-size: 14px;
        }
      }
    }
  }
}

