.tpu__leaderBoardHistory__wrapper {
  .tpu__leaderBoardHistory__section__area {
    height: calc(100vh - 108px);
    overflow-y: auto;
    .tpu__leaderBoardHistory__tab__area {
      padding: 10px;
      .tpu__leaderBoardHistory__tabs {
        .tpu__leaderBoardHistory__tab__content {
          .tpu__leaderBoardHistory__tab__wrapper {
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            .tpu__leaderBoardHistory__tab__container {
              border-radius: 10px;
              box-shadow: 0px 1px 10px 2px #bbbbbb;
              .tpu__leaderBoardHistory__tab__heading {
                background: #2f68fd;
                padding: 5px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                h2 {
                  font-size: 15px;
                  text-align: center;
                  font-family: $font-family;
                  color: #fff;
                  font-weight: 500 !important;
                }
              }
              .tpu__leaderBoardHistory__tab__details {
                background: #fff;
                overflow: hidden;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                .tpu__leaderBoardHistory__tab__leaders {
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  .tpu__leaderBoardHistory__tab__profile {
                    padding: 3px 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    img {
                      width: 50px;
                      height: 50px;
                      border-radius: 100px;
                    }
                    .profile__badge {
                      position: absolute;
                      background: red;
                      width: 18px;
                      height: 18px;
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100px;
                      color: #fff;
                      font-weight: 600 !important;
                      top: 34px;
                      right: 23px;
                      font-family: $font-family;
                    }
                    .profile__name {
                      font-size: 12px;
                      color: #000;
                      font-family: $font-family;
                      font-weight: 600 !important;
                    }
                    .tpu__profile__points {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                      border-radius: 5px;
                      background: #dedede;
                      padding: 2px 5px;
                      img {
                        width: 20px;
                        height: 20px;
                      }
                      .points {
                        font-size: 13px;
                        color: #000;
                        font-family: $font-family;
                        font-weight: 600 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
