.help-support-header {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  margin-bottom: 20px;

  border-bottom: 1px solid rgb(167, 167, 167);
  .help-support-header-text {
    color: rgb(89, 89, 89);
    font-size: 1.2rem;
    font-weight: bold !important;
  }
}

.help-support-item {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #b4b4b4;
}

.help-support-iconContainer {
  width: 35px;
  height: 35px;
  font-size: 1.3em;
  border-radius: 20px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.help-support-textContainer {
  flex: 1;
}

.help-support-title {
  font-size: 16px;
  font-weight: bold !important;
  color: #666;
}

.help-support-subtitle {
  font-size: 14px;
  color: #666;
}

.help-support-arrow {
  font-size: 1.3em;
}

.help-support-content {
  flex: 1;
  overflow-y: auto;
}

.bottom-menu-wrapper {
  margin-top: auto;
}
