.tpu__myLeads__wrapper {
    background: #edeffb;
  .tpu__myLeads__section__area {
    height: calc(100vh - 100px);
    
    overflow-y: auto;
    .tpu__myLeads__section__form {
      padding: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background: #fff;
      form {
        .tpu__myLeads__section__formGroup {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          margin-bottom: 10px;
          .tpu__myLeads__inputGroup {
            position: relative;
            label {
              color: gray;
              font-size: 12px;
              font-family: $font-family;
            }
            input,
            select {
              width: 100%;
              padding: 7px 5px;
              color: #000;
              outline: none;
              border: 1px solid lightgray;
              font-size: 13px;
              font-family: $font-family;
              padding-left: 23px;
              border-radius: 5px;
              font-weight: 600 !important;
              &:focus {
                border: 1px solid #3871ff;
              }
            }
            span {
              svg {
                position: absolute;
                top: 28px;
                left: 6px;
                font-size: 12px;
                color: gray;
              }
            }
          }
        }
        .tpu__myLeads__button {
          margin-top: 15px !important;
          button {
            width: 100%;
            padding: 7px 5px;
            color: #fff;
            background: #3871ff;
            outline: none;
            border-radius: 5px;
            border: none;
            font-size: 14px;
            font-family: $font-family;
            font-weight: 600 !important;
          }
        }
      }
    }
    .tpu__myLeads__history__card__section {
      background: #edeffb;
    //   height: 100%;
      .tpu__myLeads__history__heading {
        text-align: center;
        padding: 15px 0;
        span {
          background: #ec5120;
          color: #fff;
          padding: 5px 12px;
          font-family: $font-family;
          font-weight: 600 !important;
          border-radius: 5px;
          border: 1px solid lightgray;
          font-size: 15px;
        }
      }
      .tpu__myLeads__cards{
        padding: 10px;
        .tpu__myLeads__tabHistory {
            margin-bottom: 5px;
            background: #fff;
            border-radius: 10px;
            padding: 5px;
            .tpu__myLeads__tabHistory__heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              cursor: pointer;
              user-select: none;
              .tpu__myLeads__tabHistory__icon {
                display: flex;
                align-items: center;
                gap: 10px;
                .tpu__myLeads__tabIcon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 50px;
                  border-radius: 100px;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
                .tpu__myLeads__tabName {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;
                  h2 {
                    font-size: 13px;
                    font-family: $font-family;
                    font-weight: 600 !important;
                  }
                  span {
                    color: #000;
                    width: max-content;
                    font-size: 14px;
                    font-family: $font-family;
                  }
                }
              }
              .tpu__myLeads__tabHistory__amount {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                .tpu__myLeads__tabPrice {
                  text-align: end;
                  h3 {
                    font-size: 13px;
                    font-family: $font-family;
                    font-weight: 600 !important;
                  }
                  p {
                    color: gray;
                    font-size: 12px;
                    font-family: $font-family;
                    // font-weight: 300 !important;
                  }
                }
                .tpu__icon {
                  span {
                    svg {
                      font-size: 20px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .tpu___myLeads__tabHistory__details {
              padding: 10px 0;
              // display: none;
              .tpu__myLeads__tabHistoryDetails__heading {
                text-align: center;
                border-bottom: 1px dashed lightgray;
                padding-bottom: 10px;
                .tpu__myLeads__tabIcon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 50px;
                  border-radius: 100px;
                  margin: auto;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
                .tpu__myLeads__details__price {
                    padding: 10px 0;
                  h2 {
                    font-size: 14px;
                    color: #000;
                    font-weight: 600 !important;
                    font-family: $font-family;
                  }
                }
              }

              .tpu__myLeads__tabHistory___content {
                .tpu__tabHistory__content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: 1px solid lightgray;
                  padding: 8px 5px;
                  font-family: $font-family;
                  font-size: 13px;
                  span{
                    color: gray;
                  }
                  strong{
                    font-weight: 600 !important;
                    text-align: end;
                  }
                }
              }
            }
          }
      }
    }
  }
}
