.contact-us-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f4f8;
    padding: 1rem;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-us-container {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .contact-us-heading {
    color: #2c3e50;
    font-size: 2rem;
    text-align: center;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.5rem;
  }
  
  .contact-us-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
  }
  
  .contact-us-info {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .contact-us-icon {
    color: #3498db;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
  
  .contact-us-subheading {
    color: #34495e;
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
  }
  
  .contact-us-detail {
    color: #7f8c8d;
    font-size: 0.9rem;
    line-height: 1.5;
    margin: 0;
  }
  
  .contact-us-email {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .contact-us-email:hover {
    color: #2980b9;
    text-decoration: underline;
  }
  
  @media (max-width: 480px) {
    .contact-us-container {
      padding: 1.5rem;
    }
  
    .contact-us-heading {
      font-size: 1.75rem;
    }
  
    .contact-us-subheading {
      font-size: 1.1rem;
    }
  
    .contact-us-detail {
      font-size: 0.85rem;
    }
  }