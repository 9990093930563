@import "../../styles/responsive/breakpoints";
@import "../../styles/abstract/variables";

@keyframes skeleton-loading {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.pending_log {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 64px;
    animation: skeleton-loading 2s linear infinite alternate;
  }
  .loading {
    margin-top: 20px;
    width: 100%;
    .line-box {
      margin: auto;
      width: 50%;
      height: 5px;
      background-color: rgb(153, 192, 229);
      // border: 1px solid rgb(35, 41, 34);
      border-radius: 20px;
      .line {
        height: 5px;
        border-radius: 20px;
        background-color: #f06e42;
        animation: loading 5s linear infinite alternate;
      }
    }
  }
}
