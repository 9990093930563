.pagination {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__select {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  &__select-btn .select__control {
    border-radius: 5px;
    width: 80px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 0;
    margin-bottom: 0;
  }

  &__select-btn .select__single-value {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }

  &__select-btn .select__option {
    font-size: 12px;
    text-align: left;
  }

  &__select-btn .select__menu {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  }

  &__select-icon {
    color: gray;
    position: absolute;
    right: 10px;
    top: 8px;
  }

  .select__indicator-separator {
    display: none;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    color: #fff !important;
    padding: 4px 8px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    border: none;

    &:disabled {
      background-color: gray;
      cursor: not-allowed;
    }
  }

  &__current-page {
    color: #000;
    background-color: #fff;
    margin: 0 20px;
    padding: 4px 20px;
    border-radius: 5px;
  }
}
