.leaderBoard__modal {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 9999;
  right: 0;
  left: 0;
  &::before {
    content: "";
    position: fixed;
    background: #00000075;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 99;
    right: 0;
    left: 0;
  }
  &__content {
    position: relative;
    z-index: 999;
    background: #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;
    .leaderBoard__modal__heading {
      .close__icon {
        span {
          svg {
            position: absolute;
            right: 4px;
            top: -37px;
            background: #fff;
            font-size: 30px;
            border-radius: 100px;
            padding: 5px;
            font-weight: 700 !important;
            color: #000;
          }
        }
      }
    }
    .leaderBoard__modal__detailsInfo {
      .profile {
        text-align: center;
        margin-bottom: 10px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 100px;
        }
      }
      .userProfileDetails {
        border: 2px solid gray;
        border-radius: 10px;
        text-align: center;
        width: max-content;
        margin: auto;
        padding: 5px;
        font-family: $font-family;
        .modal__name {
          font-size: 12px;
          color: #000;
          font-weight: 600 !important;
        }
        .modal__username {
          background: green;
          color: #fff;
          font-size: 12px;
          border-radius: 5px;
          padding: 3px;
        }
        .modal__gender {
          margin-top: 3px !important;
          svg {
            margin-bottom: -4px;
          }
          .gender {
            background: #2f68fd;
            color: #fff;
            padding: 2px 5px;
            font-size: 12px;
            border-radius: 5px;
          }
        }
      }
      .modal__address {
        margin: 10px 0 !important;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 15px;
            height: 15px;
          }
          p{
            font-size: 13px;
            font-family: $font-family;
            font-weight: 600 !important;
          }
        }
      }
      .userProfile__memberDate {
        margin-top: 10px;
        text-align: center;
        border: 1px solid gray;
        width: max-content;
        margin: auto;
        font-size: 12px;
        border-radius: 5px;
        padding: 2px 5px;
        font-family: $font-family;
        span {
          font-weight: 600 !important;
          border-radius: 5px;
          padding: 2px 5px;
          svg {
            margin-bottom: -2px;
            margin-right: 5px;
            color: #2f68fd;
          }
        }
      }
      .leaderBoard__modal__title {
        margin-top: 10px !important;
        span {
          background: #2f68fd;
          color: #fff;
          font-family: $font-family;
          padding: 5px 7px;
          font-size: 12px;
          svg {
            margin-bottom: -2px;
            margin-right: 5px;
            font-size: 18px;
          }
        }
      }
      .leaderBoard__modal__stats {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .modal__stats__group {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 14px;
            font-family: $font-family;
            width: 100%;
            img {
              width: 15px;
              height: 15px;
            }
          }
          .dataType {
            background: #fc9511;
            color: #fff;
          }
          .dataPoint,
          .dataPosition {
            border: 1px solid gray;
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
}
