.tpu__mobileLoginScreen__wrapper {
  background: #0b59f9;
  position: relative;

  .tpu__mobileLoginScreen__bottomSheet__content {
    padding-top: 30px;
    .mobileLoginForm {
      .mobileLoginForm__heading {
        margin-bottom: 20px;
        p {
          color: gray;
          font-size: 12px;
          line-height: 15px;
        }
      }
      .mobileNumberField {
        background: #f4f7ff;
        border: 1px solid #ececf2;
        display: flex;
        align-items: center;
        width: 100% !important;
        padding: 7px 9px;
        border-radius: 5px;
        span {
          padding-right: 10px;
          svg {
            font-size: 15px;
            color: #000;
            margin-bottom: -3px;
          }
        }
        .mobileInput {
          border: none;
          width: 100%;
          outline: none;
          background: #f4f7ff;
        }
      }
      .forgotPassword {
        color: #0b59f9 !important;
        font-family: $font-family;
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        font-weight: 700 !important;
        margin-top: 5px !important;
      }
    }

    .submitButton {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 25px;
      .loginButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        background: #3871ff;
        border: 1px solid #3871ff;
        outline: none;
        padding: 8px 10px;
        border-radius: 5px;
        span {
          color: #fff;
          font-weight: 600 !important;
          font-family: $font-family;
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }
  }
}
